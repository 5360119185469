export const initialState = {
  userData: {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    last4SSN: "",
    phoneNumber: "",
    backOfficeName:"",
    email: "",
    addressId: "",
    isEmailVerified: false,
    updatedBy: "",
    updatedAt: "",
    addresses: [
      {
        id: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        zipcode: "",
        updatedBy: "",
        updatedAt: "",
      },
    ],
  },
  loading: {
    getUserDetails:false,
    updateUserDetails:false,
    updateCompanyDetails: false,
    updateBackOfficeDetails: false
  },
  error: {
    getUserDetails:null,
    updateUserDetails:null,
    updateCompanyDetails: null,
    updateBackOfficeDetails: null
  },
  title:{
    companyName: null
  },
  serverResponse:null,
 
};
