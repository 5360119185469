import React, { forwardRef, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Pagination, Select, Checkbox, ScrollBar } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { checkValidNumber } from "utils/helper";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

function setFilteredParams(filterArr, val, rows) {
  if (val === undefined) return undefined;

  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);

  return filterArr;
}

export function SelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
  rows,
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <>
      <div className="block">
        <span className="block capitalize mb-4">{id}</span>
        {options.map((option, i) => {
          return (
            <div key={i}>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  id={option}
                  name={option}
                  value={option}
                  onChange={(e) => {
                    setFilter(
                      setFilteredParams(filterValue, e.target.value, rows)
                    );
                  }}
                ></input>
                <label
                  htmlFor={option}
                  className="ml-1.5 font-medium text-white"
                >
                  {option}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const {
    indeterminate,
    onChange,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    disabled,
    ...rest
  } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    if (onChange) onChange(e);
    if (onCheckBoxChange) onCheckBoxChange(e);
    if (onIndeterminateCheckBoxChange) onIndeterminateCheckBoxChange(e);
  };

  return (
    <Checkbox
      disabled={disabled}
      className="mb-0"
      ref={resolvedRef}
      onChange={(_, e) => handleChange(e)}
      {...rest}
    />
  );
});

const DataTable = (props) => {
  const {
    skeletonAvatarColumns,
    columns,
    // dataLengthInPage,
    data,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    disablePagination,
    pageSizes,
    setDataLengthInPage,
    selectable,
    rowSize,
    skeletonAvatarProps,
    pagingData,
    autoResetSelectedRows,
    navigateTo,
    error
  } = props;

  const { pageSize, pageIndex, total } = pagingData;
  const navigate = useNavigate();
  const storedValue = localStorage.getItem("dataLengthInPage");
  const dataLengthInPage = checkValidNumber(storedValue)
  const size = [
    {
      id: 1,
      label: "10",
      value: 10,
    },
    {
      id: 2,
      label: "25",
      value: 25,
    },
    {
      id: 3,
      label: "50",
      value: 50,
    },
    {
      id: 4,
      label: "100",
      value: 100,
    }
  ];
  const pageSizeOption = useMemo(
    () =>
      pageSizes.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
   
      onCheckBoxChange?.(checked, row);
    
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
   
      onIndeterminateCheckBoxChange?.(checked, rows);
    
  };

  const handleNavigate = (id) => {
    const { url, idName } = navigateTo;
    // const pathID = id?.values[idName];
    const pathID = id?.original[idName];
    const navigationPath = `${url}/${pathID}`;
    navigate(navigationPath);
  };

  const isPaginationEnabled = disablePagination == null || disablePagination === false;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows } =
    useTable(
      {
        columns,
        data,
        manualPagination: isPaginationEnabled,
        manualSortBy: true,
        autoResetSelectedRows,
      },
      useFilters,
      useSortBy,
      ...(isPaginationEnabled ? [usePagination] : []),
      useRowSelect,
      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
                const modifiedOnChange = (event) => {
                  page.forEach((row) => {
                    //check each row if it is not disabled
                    !row.original.disabled &&
                      toggleRowSelected(row.id, event.currentTarget.checked);
                  });
                };

                let selectableRowsInCurrentPage = 0;
                let selectedRowsInCurrentPage = 0;
                page.forEach((row) => {
                 ( row.isSelected || row.original.isSelected) && selectedRowsInCurrentPage++;
                  !row.original.disabled && selectableRowsInCurrentPage++;
                });

                const disabled = selectableRowsInCurrentPage === 0;
                const checked =
                  (isAllPageRowsSelected ||
                    selectableRowsInCurrentPage ===
                      selectedRowsInCurrentPage) &&
                  !disabled;

                const filteredData = page.filter(
                  (each) => each.original.disabled === false
                );
                return (
                  <div>
                    <IndeterminateCheckbox
                      onChange={modifiedOnChange}
                      onCheckBoxChange={(e) => {
                        handleIndeterminateCheckBoxChange(
                          e.target.checked,
                          filteredData
                        );
                      }}
                      checked={checked}
                      disabled={disabled}
                    />
                  </div>
                );
              },
              Cell: ({ row }) => {
                return (
                  <div>
                    <IndeterminateCheckbox
                      disabled={row.original.disabled}
                      {...row.getToggleRowSelectedProps()}
                      onCheckBoxChange={(e) =>
                        handleCheckBoxChange(e.target.checked, row.original)
                      }
                    />
                  </div>
                );
              },
              sortable: false,
            },
            ...columns,
          ]);
        }
      }
    );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      const sortOrder = isSortedDesc ? "desc" : "asc";
      toggleSortBy(!isSortedDesc);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };
  const currentRows = isPaginationEnabled ? page : rows; // Use page if pagination is enabled, otherwise rows

  return (
    <Loading loading={loading} type="cover">
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <>
                    <>
                      {column.show === true ||
                      column.show === undefined ||
                      column.show === null ? (
                        <Th
                          {...column.getHeaderProps({
                            style: {
                              minWidth: column.minWidth,
                            },
                          })}
                        >
                          {column.render("Header") &&
                            (column.sortable ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => handleSort(column)}
                              >
                                {column.render("Header")}
                                <span>
                                  <Sorter sort={column.isSortedDesc} />
                                </span>
                              </div>
                            ) : (
                              <div>{column.render("Header")}</div>
                            ))}
                        </Th>
                      ) : null}
                    </>
                  </>
                );
              })}
            </Tr>
          ))}
        </THead>

        {!loading && error && <p>{error}</p>}

        {loading && (
          <TableRowSkeleton
            columns={columns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        )}
        {!loading && data.length > 0 && (
          <TBody {...getTableBodyProps()}>
            {currentRows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  onDoubleClick={() =>
                    navigateTo ? handleNavigate(row) : null
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <>
                        {cell.column.show === true ||
                        cell.column.show === undefined ? (
                          <Td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Td>
                        ) : null}
                      </>
                    );
                  })}
                </Tr>
              );
            })}
          </TBody>
        )}
      </Table>
      {isPaginationEnabled && (
        <div className="flex items-center justify-between mt-4">
          <Pagination
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
            onChange={handlePaginationChange}
          />
          <div className="hidden" style={{ minWidth: 130 }}>
            <Select
              size="sm"
              menuPlacement="top"
              isSearchable={false}
              value={pageSizeOption.filter(
                (option) => option.value === pageSize
              )}
              options={pageSizeOption}
              onChange={(option) => handleSelectChange(option.value)}
            />       
          </div>
         { rowSize && <div className="flex items-center">
            <h6 style={{ color: "#6B7280" }} className="px-2">Rows Per Page</h6>
          <Select
                options={size}
                isSearchable={false}
                value={size.filter((option) => option.value === dataLengthInPage )}
                className="text-left pr-5"
                label="page size"
                onChange={(option) => {
                  localStorage.setItem("dataLengthInPage", option.value)
                  setDataLengthInPage(option.value)
                }}
                menuPlacement="auto"
                />
                </div>}
        </div>
      )}
    </Loading>
  );
};

DataTable.propTypes = {
  error: PropTypes.any,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  autoResetSelectedRows: PropTypes.bool,
};

DataTable.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
  autoResetSelectedRows: true,
};

export default DataTable;
