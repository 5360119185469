import appConfig from "configs/app.config";
import * as Yup from "yup";

const APP_MODE = appConfig.APP_MODE;
const Domains = appConfig.DOMAIN;
const restrictedDomains = Domains.split(",").map((domain) => domain.trim().toLowerCase());
export const emailValidation = Yup.string()
  .matches(
    /^(?!\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$/,
    "Please enter a valid email"
  )
  .test("restricted-domains", "This email domain is not allowed", (value) => {
    if (APP_MODE === "Production" && value) {
      const domain = value.split("@")[1]; // Extract domain
      return !restrictedDomains.includes(domain.toLowerCase());
    }
    return true;
  });


export const checkValidNumber = (value) =>{
    return value && !isNaN(parseInt(value)) ? parseInt(value) :  10
}
