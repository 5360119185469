const appConfig = {
  api: {
    //domain: "http://localhost:5001/api", // url gets loaded dynamically in azure based on environment
    //domain:"https://ezroi-oms-dev-ewb0c6chgwgecdfp.westus-01.azurewebsites.net/api",
    domain: "https://ezroi-oms-prod.azurewebsites.net/api",
    timeout: 60000,
    retry: 3,
    documentRequest: "documentrequest?scope={scope}&filter={filter}",
    documentRequestSummary: "getRequestsSummaryForUser",
    uploadReferenceDocument: "referencedoc/upload?documentTypeId={docTypeId}",
    uploadZipFile: "referencedoc/{docId}?documentTypeId={docTypeId}",
    getUploadedZipFile: "referencedoc/{docId}/xray",
    uploadLogo: "referencedoc/upload/logo",
    downloadDocument: "referencedoc/{requestId}/download",
    downloadZipDocument: "referencedoc/{requestId}/download?isZip=true",
    downloadHippaDoc: "configuration/{orgId}/hippa/download",
    getUrlForDownload: "referencedoc/{requestId}/uri/download",
    downloadFinalDocument: "referencedoc/{requestId}/finalDocument",
    doctorFacilities: "masterdata/doctorfacility",
    attorneyOffices: "masterdata/attorneyoffice",
    requestTypes: "masterdata/requestType",
    recordTypes: "masterdata/recordType",
    services: "masterdata/services",
    requestStatusTypes: "masterdata/requestStatuses",
    internalStatusDomain: "masterdata/requestInternalStatuses",
    statusDomain: "masterdata/requestStatuses",
    adminRequestSummary: "dashboard/requestSummary",
    requestSummaryByorgId: "dashboard/documents",
    getRequestCountsByStatus: "dashboard/requestCountsByStatus",
    getAdminEntityCounts: "dashboard/adminEntityCounts",
    govertmentIdTypes: "masterdata/govertmentIdType",
    invoiceFeeTypes: "masterdata/feetypes",
    getXrayBodyParts: "masterdata/xrayBodyParts",
    getXrayCategories: "masterdata/xrayCategories",
    admin: {
      documentRequest: "documentrequest?filter={filter}",
      documentRequestSummary: "getRequestsSummaryForUser",
      documentRequestDetails: "documentrequest/{documentRequestId}",
      documentRequestStatus: "documentrequest/{documentRequestId}/status",
      documentRequestXrayBodyParts: "masterdata/xrayBodyParts",
      // documentRequestUpdateXrayBreakdown: "backoffice/{documentRequestId}/"
      documentRequestXrayBreakdown:
        "backoffice/{documentRequestId}/xrayExamBDDetails", //xrayExamInfo", // (getXray Exam info) api
      documentRequestUserFiles:
        "documentrequest/{documentRequestId}/getUserUploadedDocs",
      documentRequestAdminFiles:
        "documentrequest/{documentRequestId}/getAdminUploadDocs",
      documentRequestUploadFiles:
        "referencedoc/upload?documentTypeId={documentId}&docReqOrderId={orderId}",
      bulkactions: "bulk-actions/upload",
      bulkUploadedFiles: "bulk-actions",
      bulkUploadXrayTasks: "bulk-actions/xray",
      documentRequestUnpaid: "dashboard/unpaidAmount",
      documentRequestUnpaidSummary: "dashboard/unpaidAmount/summary",
      documentRequestUnpaidList: "dashboard/unpaidList",
      documentRequestInvoiceDetails:
        "backoffice/{documentRequestId}/invoiceItems",
      getPaidInvoice: "documentrequest/paidInvoice",
      getPaidFilterInvoice: "documentrequest/paidFilterInvoice",
      documentRequestInvoiceInfo: "backoffice/{documentRequestId}/invoiceinfo",
      documentRequestInvoiceDetailsUpdate:
        "backoffice/{documentRequestId}/patchInvoice",
      documentRequestCreateCheckPayment:
        "backoffice/{documentRequestId}/createCheckPayment",
      documentRequestCreateXrayCheckPayment:
        "backoffice/{documentRequestId}/createXrayPayment",
      documentRequestUpdateOrderDetails:
        "backoffice/{documentRequestId}/updateDocumentRequestDetails",
      getMessages: "documentrequest/{requestId}/messageThreads",
      sendMessage: "documentrequest/{requestId}/messageThread",
      generateInvoice: "backoffice/{documentId}/generateInvoice",
      generateInvoicePost: "backoffice/generateInvoice",
      generateDeclarationOfRecord:
        "documentrequestorder/{documentId}/recordDeclaration",
      certifyNoRecords: "backoffice/{documentId}/certifyNoRecords",
      getServiceFees:
        "fee/getServiceFees?processingOrgId={processingOrgId}&recordTypeId={recordTypeId}&requestTypeId={requestTypeId}&invoiceDefaultFees={invoiceDefaultFees}&docId={docId}&isCnr={isCnr}",
      getUniversalServiceFees: "fee/getUniversalServiceFees",
      writeOffPayment: "backoffice/{requestId}/writeOffPayment",
      getPaymentHistory: "backoffice/{userId}/paymentHistory",
      getPaymentHistoryByDocRequestId:
        "backoffice/{requestId}/getPaymentHistoryByDocRequestId",
      documentRequestDeleteUploadedFile: "referencedoc/{id}",
      addServiceFee: "fee/createServiceFee",
      addRecordList: "recordtypes",
      addRequestList: "requestTypes",
      getAllServiceList: "fee",
      updateServiceFee: "fee/updateServiceFee",
      getAllinvoices: "fee/invoices",
      updateDoctorFacilityStaus: "doctorfacility/change/status/{id}",
      deleteServiceFee: "fee/{id}",
      deleteFinalDocument: "documentrequestorder/{id}/recordDeclaration",
      pendingDoctorFacilityList: "admin/pending/facilities/{type}",
      updateFacilityStatus: "admin/facilities/status/{id}",
      getNotes: "note/{docId}",
      addNote: "note",
      getDFRequestTypes: "requesttypes/{orgId}",
      getDFRecordTypes: "recordtypes/{orgId}",
      publicRecordTypes: "recordtypes/public",
      getStripeIdByOrgId: "backoffice/stripeId/{id}",
      getTaskLogs: "bulk-actions/logs/{taskId}",
      support: "support",
      createFeeDueItem: "platformFee",
      platformFeeStatus: "platformFee/request/status",
      updateFeeDueItems: "platformFee/due/status",
      createFeePaymentRequest: "platformFee/request",
    },
  },
  auth: {
    login: "user/login",
    Validate: "user/verifyUser",
    otpvalidate: "user/sendVerifyotp",
    verifyOtp: "user/verifyOtp",
    verifyCaptcha: "user/verifycaptcha",
    fetchTwoFactorAnthentication: "authenticator/generate",
    verifyTwoFactorAnthentication: "authenticator/verify",
    authenticatorverify: "authenticator/verifyOtp",
    recoveryCode: "authenticator/verifyRecoveryCode",
    changePassword: "user/changepassword",
    disable2FA: "user/disable2fa",
    reset2FA: "user/reset2fa",
    enable2FA: "user/enable2fa",
    signup: "user/register",
    sendOTP: "user/sendotp",
    resetPassword: "user/resetpassword",
    verifyEmail: "user/verifyemail",
    isEmailExists: "user/checkemailexists",
    forgotPassword: "user/forgotpassword",
    verifyToken: "user/verifytoken",
    setPassword: "user/setpassword",
    logout: "user/logout",
    stripeSetupLink: "stripe/stripeSetupLink",
  },
  patient: {
    documentRequest: "documentrequest?filter={filter}",
    documentRequestSummary: "documentrequest/count",
    notifications: "notification/?filter={filter}",
    userInformation: "user/{userId}",
    updateUserDetails: "user/{userId}",
    updateCompanyDetails: "company/{companyId}",
    getPaymentHistory: "user/{requestId}/paymentHistory",
    getMessages: "documentrequest/{requestId}/messageThreads",
    sendMessage: "documentrequest/{requestId}/messageThread",
    getOrderDetails: "documentrequest/{requestId}",
    getDocumentUploadedByUser:
      "documentrequest/{requestId}/getUserUploadedDocs",
    getDocumentUploadedByAdmin:
      "documentrequest/{requestId}/getAdminUploadDocs",
    getXrayDetails: "backoffice/{requestId}/xrayExamBDDetails",
    getInvoiceDetails: "backoffice/{documentRequestId}/invoiceItems",
    getPaymentHistoryDetailsByDocRequestId:
      "backoffice/{requestId}/getPaymentHistoryByDocRequestId",
    documentRequestInvoiceInfo: "backoffice/{documentRequestId}/invoiceinfo",
    getServiceFees:
      "fee/getServiceFees?processingOrgId={processingOrgId}&recordTypeId={recordTypeId}&requestTypeId={requestTypeId}&invoiceDefaultFees={invoiceDefaultFees}&docId={docId}&isCnr={isCnr}",
    getUniversalServiceFees: "fee/getUniversalServiceFees",
    getXrayDocs: "referencedoc/{documentRequestId}/xray/docs",
    // orderList:'user/{requestId}/documentRequests'  //replaced with documnet request
  },
  newera: {
    addDoctorFacility: "doctorfacility",
    getDoctorFacilityList: "doctorfacility",
    getBackOfficeAddress: "backoffice/address/{orgId}",
    updateDoctorFacility: "doctorfacility",
    getCompanyList: "company",
    getCompanyDetails: "company/user/{requestId}",
    getProfessionalDetails: "company/professional/{requestId}",
    getCustomerDetails: "customer/user/{requestId}",
    getCompanyUserList: "company/{requestId}/users",
    getCustomerUserList: "customer/{requestId}",
    addCompany: "company",
    addCompanyUser: "company/add-user",
    updateDocumentRequestDetails: "backoffice/updateDocumentRequestDetails",
    updateprofessionaldetails: "company/{requestId}",
    updateCompanyUserDetails: "company/user/{requestId}",
    getDoctorFacilityDetails: "doctorfacility/{requestId}",
    doctorWithOrgId: "doctorfacility/orgId/{orgId}",
    attorneyWithOrgId: "attorneyOffice/orgId/{orgId}",
    getBasicUserList: "masterdata/users",
    getRequestingPartyDetails: "company/{requestId}",
    getAllUsersList: "user",
    getAllPatientsList: "patients",
    getUserDetailsById: "user/{userId}",
    checkDuplicateDoctorEmail: "doctorfacility/checkemailexists",
    checkDuplicateDoctorEin: "doctorfacility/checkeinexists",
    checkCompanyofEin: "company/checkeinexists",
    checkCompanyNmaeExist: "company/checkcompanynameexists",
    addxraybodyparts: "xraybodyparts",
    addXrayCategories: "xraycategories",
    deleteInvoice: "backoffice/{documentRequestId}/invoice/{invoiceId}",
    backOfficeAdmin: "backofficeadmin",
    insuranceCompany: "insurancecompany",
    attorneyOffice: "attorneyOffice",
    getBackOfficeList: "backofficeadmin",
    getAttorneyList: "attorneyOffice",
    getInsuranceCompanyList: "insurancecompany",
    getBackOfficeDetails: "backofficeadmin/{requestId}",
    updateBackOffice: "backofficeadmin",
    updateInsuranceCompanyDetails: "insurancecompany/{requestId}",
    getInsuranceCompanyDetails: "insurancecompany/{requestId}",
    getAttorneyOfficeDetails: "attorneyOffice/{requestId}",
    updateAttorneyOfficeDetails: "attorneyOffice",
    getUsersByType: "backofficeadmin/{userType}/list",
    publicDoctorFacilites: "doctorfacility/public",
    publicAttorneyOffice: "/attorneyOffice/public",
    getRecordTypes: "recordtypes",
    getRequestTypes: "requesttypes",
    getConfiguration: "configuration",
    getMyCustomerList: "customer",
    getOrgConfiguration: "configuration/{orgId}",
    getFacilityDetails: "doctorfacility/facility/{type}/{id}",
    updateUserStatus: "backoffice/user/{requestId}",
    ownerLogo: "backoffice/logo/{type}/{id}",
    getActivityList: "activity",
    getAllTransactionList: "transaction/all",
    getSuccessTransactionList: "transaction/success",
    getPaymentList: "payment",
    getAllFeeDueItems: "platformFee/all",
    getAllFeeRequests: "platformFee/request/all",
    getRequestIdDetails: "platformFee/request/{requestId}",
    getRequestIditems: "platformFee/request/{requestId}/items",
    createPaymentIntentForFeePayments: "platformFee/intent/bulk",
    companyEmployees: "company/employees",
    resendInvite:'company/resend'
  },
  payment: {
    createAdvancePaymentIntent: "payment/advance-payment-intent/bulk",
    createPaymentIntent: "payment/payment-intent",
    createPaymentIntentBulk: "payment/payment-intent/bulk",
    createXRayPaymentIntent: "payment/xray-payment-intent",
    stripePublishableKey:
      "pk_live_51HHDhwKp7Nd2aqpQFs3Uo2xPAU8AolckY0mtJrz2xe5rkQc27H7qiYybCprPZBdKbGOpjxd1ZUCINLjMKne1MtW800l7U1hYN0",
  },
  stripe: {
    getAccountDetails: "stripe/getAccountDetails",
    sendRefreshAccountLinkEmail: "stripe/sendRefreshAccountLinkEmail",
  },
  websiteOpenServices: {
    contactUs: "websiteOpenservices/contactus",
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  APP_MODE: "Development",
  DOMAIN: "yopmail.com"
};

// Loading runtime configuration from azure portal -> configuration -> snippets
const envConfig =
  window.config && window.config.appConfig ? window.config.appConfig : {};

if (envConfig) {
  if (envConfig.api) {
    appConfig.api = { ...appConfig.api, ...envConfig.api };
  }
  if (envConfig.payment) {
    appConfig.api = { ...appConfig.api, ...envConfig.api };
  }
}

export default appConfig;
