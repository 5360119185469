import { initialState } from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../constants";

import {
  getDocumentRequestBasicUserList,
  getDocumentRequestDetails,
  getDocumentRequestDoctorFacility,
  getDocumentRequestRecordTypes,
  getDocumentRequestRequestTypes,
  getDocumentRequestRequestingPartyDetails,
  getInternalStatusDomain,
  getStatusDomain,
  updateOrderDetails,
  getDoctorFacilities,
  getDocumentRequestInsuranceCompany,
  getDocumentRequestAttorneyOffices,
  getInsuranceCompanies,
  getAttorneyOffices
} from "./thunks/documentRequestDetailsThunk";
import {
  getDocumentRequestStatusHistory,
  getRequestStatusTypes,
  updateStatusHistory
} from "./thunks/documentRequestStatusThunk";

import {
  createDocumentRequestCheckPayment,
  writeOffPayment,
  generateInvoice,
  getDocumentRequestInvoiceDetails,
  getServiceFeesForInvoice,
  getUniversalServiceFeesForInvoice,
  getDocumentRequestInvoiceInfo,
  getFeeTypes,
  updateDocumentRequestInvoiceDetails,
  documentRequestGetPaymentHistory,
  documentRequestGetPaymentHistoryByDocRequestId
} from "./thunks/documentRequestInvoiceThunk";

import {
  getDoctorFacilitiesComplete,
  getDoctorFacilitiesFailure,
  getDoctorFacilitiesPending,
  getDocumentRequestBasicUserListComplete,
  getDocumentRequestBasicUserListFailure,
  getDocumentRequestBasicUserListPending,
  getDocumentRequestDetailsComplete,
  getDocumentRequestDetailsPending,
  getDocumentRequestDoctorFacilityComplete,
  getDocumentRequestDoctorFacilityFailure,
  getDocumentRequestDoctorFacilityPending,
  getDocumentRequestFailure,
  getDocumentRequestRecordTypesComplete,
  getDocumentRequestRecordTypesFailure,
  getDocumentRequestRecordTypesPending,
  getDocumentRequestRequestTypesComplete,
  getDocumentRequestRequestTypesFailure,
  getDocumentRequestRequestTypesPending,
  getDocumentRequestRequestingPartyDetailsComplete,
  getDocumentRequestRequestingPartyDetailsFailure,
  getDocumentRequestRequestingPartyDetailsPending,
  getInternalStatusDomainComplete,
  getInternalStatusDomainFailure,
  getInternalStatusDomainPending,
  getStatusDomainComplete,
  getStatusDomainFailure,
  getStatusDomainPending,
  updateOrderDetailsComplete,
  updateOrderDetailsFailure,
  updateOrderDetailsPending,
  getDocumentRequestInsuranceCompanyComplete,
  getDocumentRequestInsuranceCompanyFailure,
  getDocumentRequestInsuranceCompanyPending,
  getDocumentRequestAttorneyOfficesComplete,
  getDocumentRequestAttorneyOfficesFailure,
  getDocumentRequestAttorneyOfficesPending,
  getInsuranceCompaniesComplete,
  getInsuranceCompaniesFailure,
  getInsuranceCompaniesPending,
  getAttorneyOfficesComplete,
  getAttorneyOfficesFailure,
  getAttorneyOfficesPending
} from "./reducers/documentRequestDetailsReducer";
import {
  addRequestStatusReducer,
  deleteRequestStatusReducer,
  editRequestStatusReducer,
  getDocumentRequestStatusHistoryComplete,
  getDocumentRequestStatusHistoryPending,
  getDocumentRequestStatusHistoryFailure,
  getRequestStatusTypesComplete,
  getRequestStatusTypesFailure,
  getRequestStatusTypesPending,
  updateStatusHistoryComplete,
  updateStatusHistoryFailure,
  updateStatusHistoryPending,
  resetStatusHistoryApiPayloadReducer,
} from "./reducers/documentRequestStatusHistoryReducer";
import {
  addInvoiceInfo_r,
  delInvoiceInfo_r,
  editInvoiceInfo_r,
  getRequestInvoiceDetailsPending,
  getRequestInvoiceDetailsComplete,
  getRequestInvoiceDetailsFailure,
  getInvoiceDetailsByServiceFailure,
  getInvoiceDetailsByServicePending,
  getInvoiceDetailsByServiceComplete,
  getUniversalServiceFeesForInvoiceFailure,
  getUniversalServiceFeesForInvoicePending,
  getUniversalServiceFeesForInvoiceComplete,
  updateInvoiceItemsComplete,
  updateInvoiceItemsPending,
  updateInvoiceItemsFailure,
  getFeeTypesComplete,
  getFeeTypesPending,
  getFeeTypesFailure,
  getRequestInvoiceInfoComplete,
  getRequestInvoiceInfoFailure,
  getRequestInvoiceInfoPending,
  getPaymentHistoryComplete,
  getPaymentHistoryFailure,
  getPaymentHistoryPending,
  createCheckPaymentComplete,
  createCheckPaymentPending,
  createCheckPaymentFailure,
  writeOffPaymentComplete,
  writeOffPaymentPending,
  writeOffPaymentFailure,
  generateInvoicePending,
  generateInvoiceComplete,
  generateInvoiceFailure,
} from "./reducers/requestInvoiceInfoReducer";
import {
  addRequestXrayBreakdownReducer,
  deleteRequestXrayBreakdown,
  editRequestXrayBreakdownReducer,
  editRequestXrayItemReducer,
  getDocumentRequestXrayBreakdownComplete,
  getDocumentRequestXrayBreakdownFailure,
  getDocumentRequestXrayBreakdownPending,
  getXrayBodyPartsComplete,
  getXrayBodyPartsFailure,
  getXrayBodyPartsPending,
  resetXrayBreakdownApiPayloadReducer,
} from "./reducers/documentRequestXrayBreakdownReducer";

import { getDocumentRequestXrayBreakdown, getXrayBodyParts } from "./thunks/documentRequestXrayBreakdownThunk";
import {
  getDocumentRequestAdminFiles,
  getDocumentRequestUserFiles,
} from "./thunks/documentRequestFilesThunk";
import {
  getDocumentRequestUserFilesComplete,
  getDocumentRequestUserFilesFailure,
  getDocumentRequestUserFilesPending,
} from "./reducers/documentRequestUserFilesReducer";
import {
  getDocumentRequestAdminFilesComplete,
  getDocumentRequestAdminFilesFailure,
  getDocumentRequestAdminFilesPending,
} from "./reducers/documentRequestAdminFilesReducer";
import { documentRequestGetMessages, documentRequestSendMessages, documentRequestGetNotes } from "./thunks/documentRequestSupportThunk";
import { getMessagesComplete, getMessagesFailure, getMessagesPending, getNotesComplete, getNotesFailure, getNotesPending, sendMessagesComplete, sendMessagesFailure, sendMessagesPending } from "./reducers/documentRequestSupportReducer";
import { getDoctorFacilityDetailsComplete } from "views/newera/IndividualDoctorFacilityDetails/store/reducers/getDoctorFacilityDetailsReducer";

const slice = createSlice({
  name: AdminEditRequestConstants.StoreSliceName,
  initialState,
  reducers: {
    //files reducers
    openRequestFilesDialog: (state, action) => {
      state.ui.requestFilesDialogOpen = true;
    },
    closeRequestFilesDialog: (state, action) => {
      state.ui.requestFilesDialogOpen = false;
    },
    openRequestZipFilesDialog: (state, action) => {
      state.ui.requestZipFilesDialogOpen = true;
    },
    closeRequestZipFilesDialog: (state, action) => {
      state.ui.requestZipFilesDialogOpen = false;
    },

    addDownloadedFile: (state, action) => {
      const { id, fileUrl } = action.payload;
      if (!state.downloadedFiles[id]) {
        state.downloadedFiles[id] = fileUrl;
      }
    },

    //xray panel state
    setXrayPanelState: (state, action) => {
      state.metadata.requestXrayPanelState = action.payload;
    },
    // Request x-ray breakdown Reducers
    openRequestXrayDialog: (state, action) => {
      state.ui.requestXrayDialogOpen = true;
      state.ui.requestXrayDialogMode = action.payload;
    },
    closeRequestXrayDialog: (state, action) => {
      state.ui.requestXrayDialogOpen = false;
    },

    addXrayBreakdown: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      addRequestXrayBreakdownReducer(state, action);
    },

    //xray breakdown
    deleteXrayBreakdown: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      deleteRequestXrayBreakdown(state, action);
    },
    editXrayBreakdown: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      editRequestXrayBreakdownReducer(state, action);
    },
    editXrayItem: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      editRequestXrayItemReducer(state, action);
    },
    setEditableXrayBreakdownId: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      state.ui.editableDocumentRequestXrayBreakdownId = action.payload;
    },

    setXrayExamBreakdownIds: (state, action) => {
      state.xrayExamBreakdownIds = action.payload;
    },

    setConfigurationData: (state, action) => {
      state.configurationData = action.payload;
    },

    resetXrayBreakdownApiPayload: (state, action) => {
      resetXrayBreakdownApiPayloadReducer(state, action);
    },
    updateWarningDialog: (state, action) => {
      state.modifications.statusHistory = action.payload;
      state.modifications.requestInvoice = action.payload;
      state.modifications.xRayBreakdown = action.payload;
    },
    resetXrayBodyParts: (state, action) => {
      state.apiPayload.documentRequestXrayBreakdown = []
      state.modifications.xRayBreakdown = false
    },
    updateDetailsWarningDialog: (state, action) => {
      state.modifications.orderDetails = action.payload;
    },

    // Request Status Reducers
    openRequestStatusDialog: (state, action) => {
      state.ui.requestStatusDialogOpen = true;
      state.ui.requestStatusDialogMode = action.payload;
    },
    closeRequestStatusDialog: (state, action) => {
      state.ui.requestStatusDialogOpen = false;
    },
    //order details
    updateOrderDetails: (state, action) => {
      updateOrderDetails(state, action);
    },
    addStatusHistory: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      addRequestStatusReducer(state, action);
    },
    deleteStatusHistory: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      deleteRequestStatusReducer(state, action);
    },
    editStatusHistory: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      editRequestStatusReducer(state, action);
    },
    setEditableStatusHistoryId: (state, action) => {
      // state.hasStatusHistoryChanged = true;
      state.ui.editableDocumentRequestStatusHistoryId = action.payload;
    },
    resetStatusHistoryApiPayload: (state, action) => {
      resetStatusHistoryApiPayloadReducer(state, action);
    },
    addInvoiceInfo: (state, action) => {
      state.hasInvoiceInfoChanged = true;
      addInvoiceInfo_r(state, action);
    },
    deleteInvoiceInfo: (state, action) => {
      state.hasInvoiceInfoChanged = true;
      delInvoiceInfo_r(state, action);
    },
    discountChanged:(state, action) => {
      state.modifications.requestInvoice = true;
    },
    editInvoiceInfo: (state, action) => {
      state.hasInvoiceInfoChanged = true;
      editInvoiceInfo_r(state, action);
    },
    setEditableInvoiceInfoId: (state, action) => {
      state.hasInvoiceInfoChanged = true;
      state.ui.editableInvoiceInfoId = action.payload;
    },
    openEditBillingDialogue: (state, _) => {
      state.ui.isEditDialogeFeesOpen = true;
    },
    closeEditBillingDialogue: (state, _) => {
      state.ui.isEditDialogeFeesOpen = false;
    },
    openBillingDialogue: (state, action) => {
      state.ui.isEditDialogeFeesOpen = true;
      state.ui.invoiceDialogueMode = action.payload;
    },
    closeBillingDialogue: (state, _) => {
      state.ui.isEditDialogeFeesOpen = false;
    },
    openPaymentHistoryDialogue: (state, action) => {
      state.ui.isPaymentHistoryOpen = true;
    },
    closePaymentHistoryDialogue: (state, _) => {
      state.ui.isPaymentHistoryOpen = false;
    },

    resetInvoiceHistoryApiPayload: (state, _) => {
      state.apiPayload.documentRequestUpdatedInvoiceData = [];
      state.modifications.requestInvoice = false;
    },
    setInvoiceDetailsApiPayload: (state, action) =>{
      state.apiPayload.documentRequestInvoiceDetails = action.payload
    },
    openWriteOffDialog: (state, _) => {
      state.ui.isWriteOffDialogOpen = true;
    },
    closeWriteOffDialog: (state, _) => {
      state.ui.isWriteOffDialogOpen = false;
      state.serverResponse.createCheckPayment = null;
      state.errors.createCheckPayment = null;
    },
    openRecordPaymentDialog: (state, _) => {
      state.ui.isRecordPaymentDialogOpen = true;
    },
    closeRecordPaymentDialog: (state, _) => {
      state.ui.isRecordPaymentDialogOpen = false;
      state.serverResponse.createCheckPayment = null;
      state.errors.createCheckPayment = null;
    },
    openisPlatFormFeePopUp: (state, _) => {
      state.ui.isPlatFormFee = true;
    },
    closeisPlatFormFeePopUp: (state, _) => {
      state.ui.isPlatFormFee = false;
    },
    openCnrDialog: (state, _) =>{
      state.ui.isCnrDialogOpen = true
    },
    closeCnrDialog: (state, _) =>{
      state.ui.isCnrDialogOpen = false
    },
    setServerResponse: (state, action)=>{
      state.serverResponse.updateOrderDetails = null
    },
    setMessage: (state, action) => {
      state.support = [
        ...state.support,
        { ...state.support, ...action.payload },
      ];
    },
    updateFeeTypes: (state, action) => {
      const newFeeTypes = action.payload.filter((newItem) => {
        // Check if the item with the same ID already exists in state.metadata.feeTypes
        const existingItem = state.metadata.feeTypes.find(
          (item) => item.id === newItem.id
        );
        // If the item with the same ID doesn't exist, add it to the newFeeTypes array
        return !existingItem;
      });

      // Add only the new feeTypes that don't already exist in state.metadata.feeTypes
      state.metadata.feeTypes = [...state.metadata.feeTypes, ...newFeeTypes];
    },
  },
  extraReducers: (builder) => {
    builder
      // Document Request Details
      .addCase(
        getDocumentRequestDetails.fulfilled,
        getDocumentRequestDetailsComplete
      )
      .addCase(getDocumentRequestDetails.rejected, getDocumentRequestFailure)
      .addCase(
        getDocumentRequestDetails.pending,
        getDocumentRequestDetailsPending
      )
      // Document Request Doctor Facility Details
      .addCase(
        getDocumentRequestDoctorFacility.fulfilled,
        getDocumentRequestDoctorFacilityComplete
      )
      .addCase(
        getDocumentRequestDoctorFacility.rejected,
        getDocumentRequestDoctorFacilityFailure
      )
      .addCase(
        getDocumentRequestDoctorFacility.pending,
        getDocumentRequestDoctorFacilityPending
      )
        // Insurance company
      .addCase(
        getDocumentRequestInsuranceCompany.fulfilled,
        getDocumentRequestInsuranceCompanyComplete
      )
      .addCase(
        getDocumentRequestInsuranceCompany.rejected,
        getDocumentRequestInsuranceCompanyFailure
      )
      .addCase(
        getDocumentRequestInsuranceCompany.pending,
        getDocumentRequestInsuranceCompanyPending
      )

      // Attorney Offices
      .addCase(
        getDocumentRequestAttorneyOffices.fulfilled,
        getDocumentRequestAttorneyOfficesComplete
      )
      .addCase(
        getDocumentRequestAttorneyOffices.rejected,
        getDocumentRequestAttorneyOfficesFailure
      )
      .addCase(
        getDocumentRequestAttorneyOffices.pending,
        getDocumentRequestAttorneyOfficesPending
      )

      // Document Request Request Types
      .addCase(
        getDocumentRequestRequestTypes.fulfilled,
        getDocumentRequestRequestTypesComplete
      )
      .addCase(
        getDocumentRequestRequestTypes.rejected,
        getDocumentRequestRequestTypesFailure
      )
      .addCase(
        getDocumentRequestRequestTypes.pending,
        getDocumentRequestRequestTypesPending
      )

      // Document Request Record Types
      .addCase(
        getDocumentRequestRecordTypes.fulfilled,
        getDocumentRequestRecordTypesComplete
      )
      .addCase(
        getDocumentRequestRecordTypes.rejected,
        getDocumentRequestRecordTypesFailure
      )
      .addCase(
        getDocumentRequestRecordTypes.pending,
        getDocumentRequestRecordTypesPending
      )

       // DoctorFacilities
      .addCase(getDoctorFacilities.fulfilled, getDoctorFacilitiesComplete)
      .addCase(getDoctorFacilities.rejected, getDoctorFacilitiesFailure)
      .addCase(getDoctorFacilities.pending, getDoctorFacilitiesPending)

       // InsuranceCompanies
       .addCase(getInsuranceCompanies.fulfilled, getInsuranceCompaniesComplete)
       .addCase(getInsuranceCompanies.rejected, getInsuranceCompaniesFailure)
       .addCase(getInsuranceCompanies.pending, getInsuranceCompaniesPending)
      
       // attorneyOffices
       .addCase(getAttorneyOffices.fulfilled, getAttorneyOfficesComplete)
       .addCase(getAttorneyOffices.rejected, getAttorneyOfficesFailure)
       .addCase(getAttorneyOffices.pending, getAttorneyOfficesPending)
      // Document Request basic user list
      .addCase(
        getDocumentRequestBasicUserList.fulfilled,
        getDocumentRequestBasicUserListComplete
      )
      .addCase(
        getDocumentRequestBasicUserList.rejected,
        getDocumentRequestBasicUserListFailure
      )
      .addCase(
        getDocumentRequestBasicUserList.pending,
        getDocumentRequestBasicUserListPending
      )

      // internal status domain
      .addCase(
        getInternalStatusDomain.fulfilled,
        getInternalStatusDomainComplete
      )
      .addCase(
        getInternalStatusDomain.rejected,
        getInternalStatusDomainFailure
      )
      .addCase(
        getInternalStatusDomain.pending,
        getInternalStatusDomainPending
      )

      // internal status domain
      .addCase(
        getStatusDomain.fulfilled,
        getStatusDomainComplete
      )
      .addCase(
        getStatusDomain.rejected,
        getStatusDomainFailure
      )
      .addCase(
        getStatusDomain.pending,
        getStatusDomainPending
      )

      // xray body parts
      .addCase(
        getXrayBodyParts.fulfilled,
        getXrayBodyPartsComplete
      )
      .addCase(
        getXrayBodyParts.rejected,
        getXrayBodyPartsFailure
      )
      .addCase(
        getXrayBodyParts.pending,
        getXrayBodyPartsPending
      )


      // Document Request requesting party details
      .addCase(
        getDocumentRequestRequestingPartyDetails.fulfilled,
        getDocumentRequestRequestingPartyDetailsComplete
      )
      .addCase(
        getDocumentRequestRequestingPartyDetails.rejected,
        getDocumentRequestRequestingPartyDetailsFailure
      )
      .addCase(
        getDocumentRequestRequestingPartyDetails.pending,
        getDocumentRequestRequestingPartyDetailsPending
      )

      // doc req user files
      .addCase(
        getDocumentRequestUserFiles.fulfilled,
        getDocumentRequestUserFilesComplete
      )
      .addCase(
        getDocumentRequestUserFiles.rejected,
        getDocumentRequestUserFilesFailure
      )
      .addCase(
        getDocumentRequestUserFiles.pending,
        getDocumentRequestUserFilesPending
      )

      // doc req admin files
      .addCase(
        getDocumentRequestAdminFiles.fulfilled,
        getDocumentRequestAdminFilesComplete
      )
      .addCase(
        getDocumentRequestAdminFiles.rejected,
        getDocumentRequestAdminFilesFailure
      )
      .addCase(
        getDocumentRequestAdminFiles.pending,
        getDocumentRequestAdminFilesPending
      )

      // document request xray breakdown
      .addCase(
        getDocumentRequestXrayBreakdown.fulfilled,
        getDocumentRequestXrayBreakdownComplete
      )
      .addCase(
        getDocumentRequestXrayBreakdown.rejected,
        getDocumentRequestXrayBreakdownFailure
      )
      .addCase(
        getDocumentRequestXrayBreakdown.pending,
        getDocumentRequestXrayBreakdownPending
      )

      // Document Request Status History
      .addCase(
        getDocumentRequestStatusHistory.fulfilled,
        getDocumentRequestStatusHistoryComplete
      )
      .addCase(
        getDocumentRequestStatusHistory.rejected,
        getDocumentRequestStatusHistoryFailure
      )
      .addCase(
        getDocumentRequestStatusHistory.pending,
        getDocumentRequestStatusHistoryPending
      )
      .addCase(getRequestStatusTypes.fulfilled, getRequestStatusTypesComplete)
      .addCase(getRequestStatusTypes.rejected, getRequestStatusTypesFailure)
      .addCase(getRequestStatusTypes.pending, getRequestStatusTypesPending)
      .addCase(updateStatusHistory.fulfilled, updateStatusHistoryComplete)
      .addCase(updateStatusHistory.rejected, updateStatusHistoryFailure)
      .addCase(updateStatusHistory.pending, updateStatusHistoryPending)

      // Document Request Invoice Data
      .addCase(
        getDocumentRequestInvoiceDetails.fulfilled,
        getRequestInvoiceDetailsComplete
      )
      .addCase(
        getDocumentRequestInvoiceDetails.rejected,
        getRequestInvoiceDetailsFailure
      )
      .addCase(
        getDocumentRequestInvoiceDetails.pending,
        getRequestInvoiceDetailsPending
      )
      // Service Request Invoice Data
      .addCase(
        getServiceFeesForInvoice.fulfilled,  
        getInvoiceDetailsByServiceComplete
      )
      .addCase(
        getServiceFeesForInvoice.rejected,
        getInvoiceDetailsByServiceFailure
      )
      .addCase(
        getServiceFeesForInvoice.pending,
        getInvoiceDetailsByServicePending
      )
      .addCase(
        getUniversalServiceFeesForInvoice.fulfilled,
        getUniversalServiceFeesForInvoiceComplete
      )
      .addCase(
        getUniversalServiceFeesForInvoice.rejected,
        getUniversalServiceFeesForInvoiceFailure
      )
      .addCase(
        getUniversalServiceFeesForInvoice.pending,
        getUniversalServiceFeesForInvoicePending
      )
      .addCase(
        updateDocumentRequestInvoiceDetails.fulfilled,
        updateInvoiceItemsComplete
      )
      .addCase(
        updateDocumentRequestInvoiceDetails.pending,
        updateInvoiceItemsPending
      )
      .addCase(
        updateDocumentRequestInvoiceDetails.rejected,
        updateInvoiceItemsFailure
      )
      //payment history
      .addCase(
        documentRequestGetPaymentHistoryByDocRequestId.fulfilled,
        getPaymentHistoryComplete
      )
      .addCase(
        documentRequestGetPaymentHistoryByDocRequestId.rejected,
        getPaymentHistoryFailure
      )
      .addCase(
        documentRequestGetPaymentHistoryByDocRequestId.pending,
        getPaymentHistoryPending
      )

      //invoice info
      .addCase(
        getDocumentRequestInvoiceInfo.fulfilled,
        getRequestInvoiceInfoComplete
      )
      .addCase(
        getDocumentRequestInvoiceInfo.rejected,
        getRequestInvoiceInfoFailure
      )
      .addCase(
        getDocumentRequestInvoiceInfo.pending,
        getRequestInvoiceInfoPending
      )

      .addCase(getFeeTypes.fulfilled, getFeeTypesComplete)
      .addCase(getFeeTypes.pending, getFeeTypesPending)
      .addCase(getFeeTypes.rejected, getFeeTypesFailure)
      .addCase(
        createDocumentRequestCheckPayment.pending,
        createCheckPaymentPending
      )
      .addCase(
        createDocumentRequestCheckPayment.fulfilled,
        createCheckPaymentComplete
      )
      .addCase(
        createDocumentRequestCheckPayment.rejected,
        createCheckPaymentFailure
      )
      .addCase(
        writeOffPayment.pending,
        writeOffPaymentPending
      )
      .addCase(
        writeOffPayment.fulfilled,
        writeOffPaymentComplete
      )
      .addCase(
        writeOffPayment.rejected,
        writeOffPaymentFailure
      )
      .addCase(updateOrderDetails.pending, updateOrderDetailsPending)
      .addCase(updateOrderDetails.fulfilled, updateOrderDetailsComplete)
      .addCase(updateOrderDetails.rejected, updateOrderDetailsFailure)
      .addCase(documentRequestGetMessages.pending, getMessagesPending)
      .addCase(documentRequestGetMessages.fulfilled, getMessagesComplete)
      .addCase(documentRequestGetMessages.rejected, getMessagesFailure)
      .addCase(documentRequestSendMessages.pending, sendMessagesPending)
      .addCase(documentRequestSendMessages.fulfilled, sendMessagesComplete)
      .addCase(documentRequestSendMessages.rejected, sendMessagesFailure)
      //Generate invoice
      .addCase(generateInvoice.pending, generateInvoicePending)
      .addCase(generateInvoice.fulfilled, generateInvoiceComplete)
      .addCase(generateInvoice.rejected, generateInvoiceFailure)
      // get Notes
      .addCase(documentRequestGetNotes.pending, getNotesPending)
      .addCase(documentRequestGetNotes.fulfilled, getNotesComplete)
      .addCase(documentRequestGetNotes.rejected, getNotesFailure)
  },
});

export {
  //update order details
  updateOrderDetails,

  // Request Details Thunk
  getDocumentRequestDetails,
  //doctor facility details
  getDocumentRequestDoctorFacility,
  //basic user list
  getDocumentRequestBasicUserList,

  //meta data
  getDocumentRequestRecordTypes,
  getDocumentRequestRequestTypes,
  getDoctorFacilities,
  getInternalStatusDomain,
  // Request Status Thunk
  getDocumentRequestStatusHistory,
  getRequestStatusTypes,
  updateStatusHistory,
  createDocumentRequestCheckPayment,
  writeOffPayment,
  //Support
  documentRequestSendMessages,
  documentRequestGetMessages,

  //Generate invoice
  generateInvoice
};

export const {
  // request files reducers
  openRequestFilesDialog,
  closeRequestFilesDialog,
  openRequestZipFilesDialog,
  closeRequestZipFilesDialog,
  addDownloadedFile,

  // Request Xray Reducers
  setXrayPanelState,
  openRequestXrayDialog,
  closeRequestXrayDialog,
  deleteXrayBreakdown,
  addXrayBreakdown,
  editXrayBreakdown,
  editXrayItem,
  setEditableXrayBreakdownId,
  setXrayExamBreakdownIds,
  setConfigurationData,
  resetXrayBreakdownApiPayload,

  //Invoice fee type Thunk
  // getFeeTypes,
  updateWarningDialog,
  resetXrayBodyParts,
  // Request Status Reducers
  openRequestStatusDialog,
  updateDetailsWarningDialog,
  closeRequestStatusDialog,
  addStatusHistory,
  editStatusHistory,
  deleteStatusHistory,
  setEditableStatusHistoryId,
  resetStatusHistoryApiPayload,

  // Invoice Details Reducers
  addInvoiceInfo,
  editInvoiceInfo,
  discountChanged,
  deleteInvoiceInfo,
  setEditableInvoiceInfoId,
  openEditBillingDialogue,
  closeEditBillingDialogue,
  openBillingDialogue,
  closeBillingDialogue,
  openPaymentHistoryDialogue,
  closePaymentHistoryDialogue,
  resetInvoiceHistoryApiPayload,
  openWriteOffDialog,
  closeWriteOffDialog,
  openRecordPaymentDialog,
  closeRecordPaymentDialog,
  setServerResponse,
  setInvoiceDetailsApiPayload,
  openCnrDialog,
  closeCnrDialog,
  openisPlatFormFeePopUp,
  closeisPlatFormFeePopUp,
  updateFeeTypes,

  //support
  setMessage

} = slice.actions;
export default slice.reducer;
