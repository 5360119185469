export const AdminEditRequestConstants = {
    StoreSliceName: 'AdminEditRequest'
};

export const InternalToOrderStatusMap = {
    1: 1,    
    2: 2,
    4: 2,
    5: 2,
    6: 2,
    7: 2,
    9: 2,
    10: 2,
    11: 2,
    12: 2,
    3: 3,
    8: 4   
  };

  export const XRAY ='X-Ray'

  export const requestInternalStatus = {
    NEW_REQUEST: 1,
    DOCUMENT_UNDER_REVIEW: 2,
    REJECTED: 3,
    APPROVED: 4,
    SEARCHING_FOR_RECORDS: 5,
    NO_RECORDS_FOUND: 6,
    READY_TO_INVOICE: 7,
    PENDING_PAYMENT: 8,
    BD_PARTS_ADDED: 9,
    X_RAY_BD_PENDING: 10,
    X_RAY_BD_DUPLICATION: 11,
    X_RAY_BD_FILES_IMPORTED: 12,
    READY_FOR_DOWNLOAD: 13,
    COMPLETED: 14,
    X_RAY_WAITING_FOR_APPROVAL: 15,
    X_RAY_APPROVED: 16,
    ADVANCE_FEE_PENDING: 17  
  };
  