import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import {
  apiIsEmailExists,
  apiSendOtp,
  apiSignIn,
  apiSignUp,
  apiVerifyCaptcha,
  apiVerifyEmail,
  authenticatorOtp2FA,
  validateUser,
  verifyOtp2FA
} from "services/AuthService";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setIsUserEmailVerified,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { redirect, useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import jwt_decode from "jwt-decode";
import {
  getUserInformation,
  setUserInformation,
} from "views/patient/profile/store";
import { signOutGlobally } from "store/auth/authStore";


function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn, isUserEmailVerified } = useSelector(
    (state) => state.auth.session
  );
  const { isEmailVerified } = useSelector(
    (state) => state.userInformation.userData
  );

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);

      if (resp.data) {
        if (resp?.data?.resetpassword) {
          return {
            status: "resetpassword",
            message: "",
          };
        }

        const { token } = resp.data;
        const decode_token = jwt_decode(token);
        const {
          userId,
          userName,
          firstName,
          lastName,
          claims,
          organisationName,
          roles,
          userOrgId,
        } = decode_token;

        const rolesData = [];
        roles.forEach((element) => {
          rolesData.push(element);
        });

        // set token in local storage
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            organisationName,
            userId,
            userName,
            firstName,
            lastName,
            rolesData,
            userOrgId,
          })
        );

        localStorage.setItem(
          "isPasswordExpiryIn2Days",
          JSON.stringify(resp.data.isPasswordExpiryIn2Days ?? false)
        );
        localStorage.setItem("remainDays", resp.data.remainDays);
        localStorage.setItem("isLogin", resp.data.isLogin);
        localStorage.setItem("dataLengthInPage", 10);
        dispatch(getUserInformation(userId));

        dispatch(onSignInSuccess(token));

        if (decode_token) {
          dispatch(
            setUser({
              userId: userId,
              userName: userName,
              firstName: firstName,
              lastName: lastName,
              authority: rolesData,
              userOrgId,
              organisationName,
              isLogin: resp.data.isLogin,
            })
          );
        }

        // const redirectUrl = query.get(REDIRECT_URL_KEY);
        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const UserValidation = async (values) =>{
    try{
      const resp = await validateUser(values);      
      if(resp){
        return resp
      }
    }catch(errors){
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  }

  const verifyOtp = async (values, type) =>{
    try{
      let response
      if(type === "auth" ){
        response = await authenticatorOtp2FA(values)
      }else {
        response = await verifyOtp2FA(values);
      }

      if(response?.data.success){
        return {
          status: "success",
          token: response?.data?.token
        }
      }else {
      return {
        status: "failed",
        token: null
      }
    }
    }catch(errors){
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  }
  const signOut = async (values) => {
    let result = signOutGlobally();
    if (result?.status == "success") {
      navigate(appConfig.unAuthenticatedEntryPath);
    }
    return result;
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      const msg = errors?.response?.data;
      return {
        status: "failed",
        message: msg?.error || msg || errors.toString(),
      };
    }
  };

  const verifyEmail = async (values) => {
    try {
      const resp = await apiVerifyEmail(values);
      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const verifyCaptcha = async (values) => {
    try {
      const resp = await apiVerifyCaptcha(values);
      return {
        data: resp.data,
        status: "success",
        message: "Captcha verified successfully",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const resendOtp = async (values) => {
    try {
      const resp = await apiSendOtp(values);
      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const isEmailExists = async (values) => {
    try {
      const resp = await apiIsEmailExists(values);
      if (resp.data) {
        localStorage.setItem("userEmail", JSON.stringify(values.email));
      }
      return {
        status: "success",
        message: "",
        data: resp,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  // const handleSignOut = () => {
  //   localStorage.setItem("token", null);
  //   localStorage.setItem("userData", null);
  //   localStorage.setItem("userEmail", null);
  //   dispatch(onSignOutSuccess());
  //   dispatch(userLoggedOut());
  //   dispatch(setUserInformation());
  //   dispatch({ type: "reset" });
  //   navigate(appConfig.unAuthenticatedEntryPath);
  // };

  // const signOut = async () => {
  //   handleSignOut();
  // };

  if (isEmailVerified) {
    dispatch(setIsUserEmailVerified(isEmailVerified));
  }

  return {
    authenticated: token && signedIn && isUserEmailVerified,
    signIn,
    signUp,
    signOut,
    verifyEmail,
    resendOtp,
    verifyCaptcha,
    isEmailExists,
    UserValidation,
    verifyOtp
  };
}

export default useAuth;
